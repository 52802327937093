import { TechRadarApi } from '@backstage-community/plugin-tech-radar';
import { TechRadarLoaderResponse } from '@backstage-community/plugin-tech-radar-common';
import defaultData from './defaultData.json';
import cloudStatusData from './couldStatusData.json';
import supportStatusData from './supportStatusData.json';

export class CustomTechRadarApi implements TechRadarApi {
    async load(id: string | undefined): Promise<TechRadarLoaderResponse> {
        switch (id) {
            case 'support-status':
                return (supportStatusData as unknown as TechRadarLoaderResponse);
            case 'cloud-status':
                return (cloudStatusData as unknown as TechRadarLoaderResponse);
            case 'default':
            default:
                return (defaultData as unknown as TechRadarLoaderResponse);
        }
    }
}