import React from 'react';
import {
  Content,
  ContentHeader,
  SupportButton,
} from '@backstage/core-components';
import { Page } from '@backstage/core-components';

export const HomePage = () => (
  <Page themeId="home">
    <Content>
      <ContentHeader title="Welcome to the Bootstrap Platform Community">
        <SupportButton>Need help?</SupportButton>
      </ContentHeader>
      <div>
        <p>
          This community takes care of Globant Internal Developer Platform. We maintain and evolve an IDP (or Internal Developer Portal) with a set of Golden Paths, as well as the Consulting offerings material.
        </p>

        <h2>Collaboration Requirements</h2>
        <ul>
          <li>Be a Glober (of any Studio/Portfolio/Account)</li>
          <li>Have a Globant computer</li>
          <li>
            Have a <a href="https://github.com/enterprises/globant-emu">Globant EMU</a> account on github.com <a href="#">get access</a>
          </li>
          <li>
            Have access to the <a href="https://globant.atlassian.net/jira/software/c/projects/GL0755/boards/1168">Bootstrap Community</a> Kanban boards in Jira <a href="#">get access</a>
          </li>
          <li>
            Have access to the Bootstrap Community slack channel <a href="#">get access</a>
          </li>
          <li>
            Access to the Community <a href="https://drive.google.com/drive/folders/1UAXk6D2uvpXs6-6NKa4mXVe1lkVguw4e">Google Drive</a>
          </li>
        </ul>

        <h2>Understanding the Backlog fundamentals</h2>
        <p>
          We have two main Kaban Boards in Jira:
        </p>
        <ul>
          <li>
            <a href="https://globant.atlassian.net/jira/software/c/projects/GL0755/boards/1168">Bootstrap Platform</a> is the board where you can find all the workstreams related to the Platform itself and its evolution
          </li>
          <li>
            <a href="https://globant.atlassian.net/jira/software/c/projects/GL0755/boards/1169?issueParent=448018">STC Offering</a> is the board where you can find all the work related to the consultancy offerings and its evolution
          </li>
        </ul>
        <p>
          Jira offers a broad set of alternatives to configure your issues. The conventions that we use to organize the work are the following:
        </p>
        <ul>
          <li>
            We use <a href="https://globant.atlassian.net/jira/software/c/projects/GL0755/components">Components</a> to typify workstreams like Offering evolution and other ongoing / long-running services
          </li>
          <li>
            We use <a href="https://globant.atlassian.net/jira/software/c/projects/GL0755/list?hideDone=true&sortBy=key&direction=DESC&filter=type%20%3D%20%22Epic%22">Epics</a> to identify units of work that belong to one or more Components and need to be broken down into more granular Tasks or Stories. An Epic could be long-running but we prefer to think that they have a DOD and can be completed.
          </li>
        </ul>

        <h2>How to collaborate</h2>
        <p>
          We recommend browsing both Boards to see what is available on the "TO DO" column and assign to yourself an issue that fits your capability and interest. You can then interact with the community and the reporter via Slack or comments in the issue itself.
        </p>

        <h2>Community Demos</h2>
        <p>
          Every time there is a significant progress on an Epic or Component we will schedule a Demo to present it to the community. If you are interested in presenting at our next Demo session, connect with us on the <a href="https://globant.enterprise.slack.com/archives/C03K7NABVV0">Slack Channel</a>.
        </p>

        <h2>Community Resources</h2>
        <ul>
          <li>
            <a href="https://sites.google.com/globant.com/bootstrap-framework/home">Google Site Portal</a>: You can find there all the Project Execution Toolkits
          </li>
          <li>
            <a href="https://drive.google.com/drive/folders/1UAXk6D2uvpXs6-6NKa4mXVe1lkVguw4e">Google Drive</a>: You can find there toolkits, reference implementations that we are developing with collaborators cross-studio. Also, there is material to learn and explain, and organize ourselves.
          </li>
          <li>
            <a href="https://github.com/OZ-GL0624-FS">Github Organization</a>: All the repos to accelerate your project!
          </li>
          <li>
            <a href="https://globant.enterprise.slack.com/archives/C03K7NABVV0">Slack Channel</a>: Keep in touch and updated on Community news! We have all the resources referenced in the channel bookmarks. We have frequent cool demos that you can join to!
          </li>
          <li>
            We have several cloud environments that are our labs to continuously evolve the production readiness of the reference implementations and test new cool tech stuff: Check access below!
          </li>
        </ul>

        <h2>Recommended readings [WIP]</h2>
        <p>
          These are some recommended readings about Platform Engineering, Golden Paths, and our Offering Model.
        </p>
      </div>
    </Content>
  </Page>
);